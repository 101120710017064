/**
 * @generated SignedSource<<03484b997bb7c6a56ad6babcdccc7ff8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionPageContainer_data$data = {
  readonly collection: {
    readonly isSensitiveContent: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"CollectionPageHead_collection" | "CollectionPageLayout_collection" | "CollectionTrackingContext_collection" | "useIsOffersTabEnabled_collection" | "useIsRarityEnabled_collection">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignAnnouncementModal_data">;
  readonly " $fragmentType": "CollectionPageContainer_data";
};
export type CollectionPageContainer_data$key = {
  readonly " $data"?: CollectionPageContainer_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionPageContainer_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "collection"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionPageContainer_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "collection",
          "variableName": "collection"
        }
      ],
      "concreteType": "CollectionType",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isSensitiveContent",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CollectionPageHead_collection"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CollectionPageLayout_collection"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useIsRarityEnabled_collection"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useIsOffersTabEnabled_collection"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CollectionTrackingContext_collection"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignAnnouncementModal_data"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "31be5f54fe106715edb32ab9e93360e6";

export default node;

/**
 * @generated SignedSource<<4df84ac87fa4419a9e422828f367ac07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionPageHead_collection$data = {
  readonly description: string | null;
  readonly dropv2: {
    readonly stages: ReadonlyArray<{
      readonly perWalletLimit?: number | null;
    }>;
  } | null;
  readonly name: string;
  readonly owner: {
    readonly address: string;
    readonly displayName: string | null;
  } | null;
  readonly slug: string;
  readonly " $fragmentSpreads": FragmentRefs<"collection_url">;
  readonly " $fragmentType": "CollectionPageHead_collection";
};
export type CollectionPageHead_collection$key = {
  readonly " $data"?: CollectionPageHead_collection$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionPageHead_collection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionPageHead_collection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "dropv2",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "stages",
          "plural": true,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "perWalletLimit",
                  "storageKey": null
                }
              ],
              "type": "DropStage721PublicLinearPricingType",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "collection_url",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCategory",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};
})();

(node as any).hash = "f04d167ef0b0f2b4d8fc54b82a971fc6";

export default node;

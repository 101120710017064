import React from "react"
import { useIsLessThanSm, Text, FlexColumn } from "@opensea/ui-kit"
import styled from "styled-components"
import { Button } from "@/design-system/Button"
import { useTranslate } from "@/hooks/useTranslate"

type SensitiveContentScreenProps = {
  handleOnClick: () => void
}

export const SensitiveContentScreen = ({
  handleOnClick,
}: SensitiveContentScreenProps) => {
  const isMobile = useIsLessThanSm()
  const t = useTranslate("components")

  const titleText = t("sensitiveContent.title", "Sensitive content")
  const descText = t(
    "sensitiveContent.description",
    "This collection contains sensitive content that violates OpenSea's Terms of Service, and can't be bought, sold, or transferred using OpenSea.",
  )
  const buttonText = t("sensitiveContent.buttonText", "Show content")

  return (
    <FlexColumn className="fixed z-[1000] h-full w-full items-center justify-center gap-5 overflow-hidden bg-black/60 px-8 text-center">
      <Text.Heading className="text-white" size={isMobile ? "medium" : "large"}>
        {titleText}
      </Text.Heading>
      <Text.Body
        className="max-w-[500px] text-white"
        size={isMobile ? "small" : "medium"}
      >
        {descText}
      </Text.Body>
      <ShowContentButton variant="secondary" onClick={handleOnClick}>
        {buttonText}
      </ShowContentButton>
    </FlexColumn>
  )
}

const ShowContentButton = styled(Button)`
  color: white;
  background: rgba(255, 255, 255, 0.24);
  backdrop-filter: blur(12px);
  border-radius: 12px;
  border: none;
`

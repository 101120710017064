/**
 * @generated SignedSource<<011ee2fa7564f39db703a1d57da92ef6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionHeroBannerV2_data$data = {
  readonly bannerImageUrl: string | null;
  readonly description: string | null;
  readonly logo: string | null;
  readonly metadata: {
    readonly hero: {
      readonly desktopHeroMedia: {
        readonly imageUrl?: string;
        readonly mediaType?: string;
        readonly playbackId?: string;
        readonly thumbnailUrl?: string;
        readonly videoUrl?: string;
      };
      readonly mobileHeroMedia: {
        readonly imageUrl?: string;
        readonly mediaType?: string;
        readonly playbackId?: string;
        readonly thumbnailUrl?: string;
        readonly videoUrl?: string;
      };
    } | null;
  } | null;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"BaseCollectionHeroBanner_data">;
  readonly " $fragmentType": "CollectionHeroBannerV2_data";
};
export type CollectionHeroBannerV2_data$key = {
  readonly " $data"?: CollectionHeroBannerV2_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionHeroBannerV2_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaType",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
},
v2 = [
  {
    "kind": "InlineFragment",
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "imageUrl",
        "storageKey": null
      }
    ],
    "type": "ImageMediaType",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "videoUrl",
        "storageKey": null
      },
      (v1/*: any*/)
    ],
    "type": "VideoMediaType",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "playbackId",
        "storageKey": null
      }
    ],
    "type": "MuxVideoMediaType",
    "abstractKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionHeroBannerV2_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bannerImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BaseCollectionHeroBanner_data"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionMetadataType",
      "kind": "LinkedField",
      "name": "metadata",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "HeroMetadataType",
          "kind": "LinkedField",
          "name": "hero",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "desktopHeroMedia",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "mobileHeroMedia",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};
})();

(node as any).hash = "be212fc456fd6f12349c83add0dfb5a5";

export default node;

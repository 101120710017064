import React from "react"
import { useFragment } from "react-relay"
import { CollectionHeroBannerV2_data$key } from "@/lib/graphql/__generated__/CollectionHeroBannerV2_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { BaseCollectionHeroBanner } from "./components/BaseCollectionHeroBanner"

type CollectionHeroBannerProps = {
  dataKey: CollectionHeroBannerV2_data$key | null
}

export const CollectionHeroBanner = ({
  dataKey,
}: CollectionHeroBannerProps) => {
  const data = useFragment(
    graphql`
      fragment CollectionHeroBannerV2_data on CollectionType {
        name
        description
        bannerImageUrl
        logo
        ...BaseCollectionHeroBanner_data
        metadata {
          hero {
            desktopHeroMedia {
              ... on ImageMediaType {
                mediaType
                imageUrl
              }
              ... on VideoMediaType {
                mediaType
                videoUrl
                thumbnailUrl
              }
              ... on MuxVideoMediaType {
                mediaType
                thumbnailUrl
                playbackId
              }
            }
            mobileHeroMedia {
              ... on ImageMediaType {
                mediaType
                imageUrl
              }
              ... on VideoMediaType {
                mediaType
                videoUrl
                thumbnailUrl
              }
              ... on MuxVideoMediaType {
                mediaType
                thumbnailUrl
                playbackId
              }
            }
          }
        }
      }
    `,
    dataKey,
  )

  // We use a base component to render the banner, so that we can easily
  // override the data passed here in the collection page WYSIWYG
  return (
    <BaseCollectionHeroBanner
      dataKey={data}
      description={data?.description ?? ""}
      desktopHeroMedia={data?.metadata?.hero?.desktopHeroMedia}
      legacyBannerImageUrl={data?.bannerImageUrl}
      mobileHeroMedia={data?.metadata?.hero?.mobileHeroMedia}
      name={data?.name ?? ""}
      profileImageUrl={data?.logo}
    />
  )
}

import React, { ComponentProps, useEffect, useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import styled, { css } from "styled-components"
import { SensitiveContentScreen } from "@/components/common/SensitiveContentScreen"
import { AccountOrCollectionPageContextProvider } from "@/components/layout/AccountOrCollectionPage/utils/AccountOrCollectionPageContextProvider.react"
import { CampaignAnnouncementModal } from "@/components/modals/CampaignAnnouncementModal.react"
import { ErrorPage } from "@/components/pages/ErrorPage"
import { useIsRarityEnabled } from "@/components/search/rarity/useIsRarityEnabled"
import { OpenSeaPage } from "@/containers/OpenSeaPage.react"
import { Flex } from "@/design-system/Flex"
import { useIsMerchandisingViewEnabled } from "@/hooks/useFlag"
import { CollectionTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/CollectionTrackingContext.react"
import { PageTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/PageTrackingContext/PageTrackingContext.react"
import { CollectionPageContainer_data$key } from "@/lib/graphql/__generated__/CollectionPageContainer_data.graphql"
import {
  CollectionPageHead,
  CollectionPageHeadProps,
} from "./CollectionPageHead.react"
import { CollectionTab } from "./CollectionTabs.react"
import {
  CollectionPageLayout,
  CollectionPageLayoutProps,
} from "./components/CollectionPageLayout/CollectionPageLayout.react"
import { useIsOffersTabEnabled } from "./hooks/useIsOffersTabEnabled"

type Props = {
  children: React.ReactNode
  data: CollectionPageContainer_data$key | null
  tab: CollectionTab
} & Pick<
  ComponentProps<typeof AccountOrCollectionPageContextProvider>,
  "initialSelectedCardVariant" | "initialSidebarOpen"
> &
  Omit<CollectionPageHeadProps, "dataKey"> &
  Omit<CollectionPageLayoutProps, "dataKey">

export const CollectionPageContainer = ({
  children,
  data: dataKey,
  initialSelectedCardVariant,
  initialSidebarOpen,
  stringTraits,
  tab,
}: Props) => {
  const data = useFragment<CollectionPageContainer_data$key>(
    graphql`
      fragment CollectionPageContainer_data on Query
      @argumentDefinitions(collection: { type: "CollectionSlug!" }) {
        collection(collection: $collection) {
          isSensitiveContent
          ...CollectionPageHead_collection
          ...CollectionPageLayout_collection
          ...useIsRarityEnabled_collection
          ...useIsOffersTabEnabled_collection
          ...CollectionTrackingContext_collection
        }
        ...CampaignAnnouncementModal_data
      }
    `,
    dataKey,
  )

  const isRarityEnabled = useIsRarityEnabled(data?.collection ?? null)
  const isMerchandisingViewEnabled = useIsMerchandisingViewEnabled()
  const [isSensitivityFilterVisible, setIsSensitivityFilterVisible] =
    useState<boolean>(data?.collection?.isSensitiveContent || false)
  useEffect(() => {
    setIsSensitivityFilterVisible(!!data?.collection?.isSensitiveContent)
  }, [data?.collection?.isSensitiveContent])

  const isCollectionOffersPageEnabled = useIsOffersTabEnabled(
    data?.collection ?? null,
  )
  if (tab === "offers" && !isCollectionOffersPageEnabled) {
    return <ErrorPage statusCode={404} />
  }

  return (
    <CollectionTrackingContextProvider collection={data?.collection ?? null}>
      <PageTrackingContextProvider data={data} name="Collection" tab={tab}>
        <AccountOrCollectionPageContextProvider
          initialSelectedCardVariant={initialSelectedCardVariant}
          initialSidebarOpen={initialSidebarOpen}
          isCollectionRarityEnabled={isRarityEnabled}
          isListViewEnabled
        >
          <OpenSeaPage
            hasTransparentNavbar={isMerchandisingViewEnabled}
            hideFooter
          >
            <CollectionPageHead
              dataKey={data?.collection}
              showTwitterMetadata
            />
            {isSensitivityFilterVisible && (
              <SensitiveContentScreen
                handleOnClick={() => setIsSensitivityFilterVisible(false)}
              />
            )}
            <Container $isSensitivityFilterVisible={isSensitivityFilterVisible}>
              <CollectionPageLayout
                dataKey={data?.collection}
                stringTraits={stringTraits}
                tab={tab}
              >
                {children}
              </CollectionPageLayout>
            </Container>
            <CampaignAnnouncementModal dataKey={data ?? null} />
          </OpenSeaPage>
        </AccountOrCollectionPageContextProvider>
      </PageTrackingContextProvider>
    </CollectionTrackingContextProvider>
  )
}

const Container = styled(Flex)<{ $isSensitivityFilterVisible: boolean }>`
  ${props =>
    props.$isSensitivityFilterVisible &&
    css`
      overflow-y: hidden;
      position: fixed;
      filter: blur(60px);
    `}
`

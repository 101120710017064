/**
 * @generated SignedSource<<f4ee5254aecbff440def16bfc3f129a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionPageLayout_collection$data = {
  readonly slug: string;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionFooter_collection" | "CollectionHeroBannerV2_data" | "CollectionInspiredBy_data" | "CollectionTabs_collection" | "SweepContextProvider_collection" | "useIsCollectionFooterEnabled_collection">;
  readonly " $fragmentType": "CollectionPageLayout_collection";
};
export type CollectionPageLayout_collection$key = {
  readonly " $data"?: CollectionPageLayout_collection$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionPageLayout_collection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionPageLayout_collection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionHeroBannerV2_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionTabs_collection"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionFooter_collection"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SweepContextProvider_collection"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useIsCollectionFooterEnabled_collection"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionInspiredBy_data"
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "391a70b5c40bd6e210d62a5209771939";

export default node;

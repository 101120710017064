/**
 * @generated SignedSource<<921e7dcf7faf4913c46093a628a2e202>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CampaignAnnouncementModal_data$data = {
  readonly campaignAnnouncementModal: ReadonlyArray<{
    readonly campaignId: string;
    readonly ctaText: string | null;
    readonly ctaUrl: string | null;
    readonly description: string | null;
    readonly overrideUrl: string | null;
    readonly playbackId: string | null;
    readonly thumbnailUrl: string | null;
    readonly title: string;
  }>;
  readonly " $fragmentType": "CampaignAnnouncementModal_data";
};
export type CampaignAnnouncementModal_data$key = {
  readonly " $data"?: CampaignAnnouncementModal_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignAnnouncementModal_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignAnnouncementModal_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CampaignAnouncementModalType",
      "kind": "LinkedField",
      "name": "campaignAnnouncementModal",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "campaignId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "overrideUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ctaText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ctaUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "playbackId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnailUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "6327499da045f16256dcc4efa7f46adb";

export default node;

import React from "react"
import { useIsLessThanSm, Text, FlexColumn, Flex } from "@opensea/ui-kit"
import styled from "styled-components"
import { useSwiper } from "swiper/react"
import { Button } from "@/design-system/Button"
import { Modal } from "@/design-system/Modal"
import { resizeImage } from "@/lib/helpers/urls"
import { MuxVideo } from "../common/MuxVideo"

type Props = {
  title?: string
  description?: string
  overrideUrl?: string
  isFinalCard: boolean
  localStorageKey: string
  playbackId?: string
  thumbnailUrl?: string
  onClose: () => void
}

export const CampaignAnnouncementModalCard = ({
  title,
  description,
  overrideUrl,
  playbackId,
  thumbnailUrl,
  isFinalCard,
  localStorageKey,
  onClose,
}: Props) => {
  const swiper = useSwiper()
  const isMobile = useIsLessThanSm()

  return (
    <>
      {overrideUrl && (
        <StyledModalHeader
          $imgSrc={resizeImage(overrideUrl, { size: 1500 }) || ""}
          height={{ _: "228px", sm: "366px" }}
        ></StyledModalHeader>
      )}
      {playbackId && (
        <StyledModalHeader height={{ _: "228px", sm: "366px" }}>
          <MuxVideo
            autoPlay="muted"
            loop
            metadata={{
              video_title: title,
            }}
            muted
            playbackId={playbackId}
            playsInline
            poster={thumbnailUrl}
            style={{ aspectRatio: "1/1" }}
          />
        </StyledModalHeader>
      )}
      <StyledModalBody height="undefined">
        <FlexColumn className="h-full">
          <FlexColumn className="mb-6 grow items-center text-center">
            <Text.Heading
              className="-mt-2.5 mb-2 sm:mt-0"
              size={isMobile ? "small" : "large"}
            >
              {title}
            </Text.Heading>
            <Text.Body
              className="mt-2 text-secondary"
              size={isMobile ? "small" : "medium"}
            >
              {description}
            </Text.Body>
          </FlexColumn>

          <Flex className="mb-4">
            <Button
              className="w-full"
              onClick={() => {
                if (isFinalCard) {
                  localStorage.setItem(localStorageKey, "true")
                  onClose()
                } else {
                  swiper.slideNext(500)
                }
              }}
            >
              {isFinalCard ? "Done" : "Next"}
            </Button>
          </Flex>
        </FlexColumn>
      </StyledModalBody>
    </>
  )
}

const StyledModalHeader = styled(Modal.Header)<{ $imgSrc?: string }>`
  background-color: black;
  ${({ $imgSrc }) => $imgSrc && `background-image: url("${$imgSrc}");`}
  background-size: cover;
  border-radius: 16px 16px 0 0;
  width: 100%;
  padding: 0;
`

const StyledModalBody = styled(Modal.Body)`
  padding: 32px 24px 24px 24px;
`

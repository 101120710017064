import { graphql, useFragment } from "react-relay"
import { useIsSeaportEnabled } from "@/hooks/useIsSeaportEnabled"
import { useIsCollectionFooterEnabled_collection$key } from "@/lib/graphql/__generated__/useIsCollectionFooterEnabled_collection.graphql"

export const useIsCollectionFooterEnabled = (
  collectionDataKey: useIsCollectionFooterEnabled_collection$key | null,
) => {
  const collection = useFragment(
    graphql`
      fragment useIsCollectionFooterEnabled_collection on CollectionType {
        defaultChain {
          identifier
        }
      }
    `,
    collectionDataKey,
  )

  const isSeaportEnabled = useIsSeaportEnabled(
    collection?.defaultChain.identifier,
  )

  if (!isSeaportEnabled) {
    return false
  }

  return true
}

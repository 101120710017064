import React, { useMemo } from "react"
import { graphql, useFragment } from "react-relay"
import { OpenSeaHead, pageTitle } from "@/features/seo"
import { TwitterMetadata } from "@/features/seo/components/Head"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"
import { CollectionPageHead_collection$key } from "@/lib/graphql/__generated__/CollectionPageHead_collection.graphql"
import { getCollectionUrl } from "@/lib/helpers/collection"
import { Maybe } from "@/lib/helpers/type"
import * as sidecar from "@/server/sidecar/config"
import { DEFAULT_IMG } from "../../../../constants"

export type CollectionPageHeadProps = {
  dataKey: Maybe<CollectionPageHead_collection$key>
  showTwitterMetadata?: boolean
}

export const CollectionPageHead = ({
  dataKey,
  showTwitterMetadata = false,
}: CollectionPageHeadProps) => {
  const t = useTranslate("collections")
  const { origin } = useRouter()
  const collection = useFragment(
    graphql`
      fragment CollectionPageHead_collection on CollectionType {
        name
        description
        slug
        owner {
          address
          displayName
        }
        dropv2 {
          stages {
            ... on DropStage721PublicLinearPricingType {
              perWalletLimit
            }
          }
        }
        ...collection_url
      }
    `,
    dataKey ?? null,
  )

  const image = collection
    ? `${sidecar.getValue("ogImagesPreviewUrl")}/v1/collections/${
        collection.slug
      }`
    : DEFAULT_IMG

  const collectionSuffix = t("page.collectionSuffix", "Collection")

  const collectionUrl = collection
    ? `${origin}${getCollectionUrl(collection)}`
    : undefined

  const twitterMetadata: TwitterMetadata = {
    title: collection?.name
      ? `${collection.name} - ${collectionSuffix}`
      : collectionSuffix,
    image,
    imageAlt: collection?.name ?? "",
    author: collection?.owner?.displayName ?? collection?.owner?.address ?? "",
    url: collectionUrl || "",
  }

  const farcasterFrameMetadata = useMemo(
    () =>
      collection?.slug && collection.name
        ? {
            collectionSlug: collection.slug,
            collectionName: collection.name,
            hasAmount: collection.dropv2?.stages.some(
              stage => stage.perWalletLimit && stage.perWalletLimit > 1,
            ),
          }
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collection?.slug, collection?.name],
  )

  return (
    <OpenSeaHead
      description={collection?.description || ""}
      farcasterFrameMetadata={farcasterFrameMetadata}
      image={image}
      title={pageTitle(
        collection?.name
          ? `${collection.name} - ${collectionSuffix}`
          : collectionSuffix,
      )}
      twitterMetadata={showTwitterMetadata ? twitterMetadata : undefined}
      url={collectionUrl}
    />
  )
}

import React, { useMemo } from "react"
import { IS_SERVER } from "@/constants/environment"
import { useRouter } from "@/hooks/useRouter"
import { PAGE_CONTEXT_SERVER_DEFAULTS } from "../../constants"
import { TrackingContextProvider } from "../../TrackingContext.react"
import { PageViewTracker } from "./PageViewTracker.react"
import { AnyPageTab, PageName, PageTab } from "./types"

type PageContext = {
  name: PageName
  tab: AnyPageTab | undefined
  path: string
  queryString: string
  title: string
  url: string
}

export type PageTrackingContext = {
  Page: PageContext
}

type PageTrackingContextProviderProps<T extends PageName> = {
  name: T
  tab?: PageTab<T>
  /**
   * This prop should be used with data from the initial page load to ensure only one page view event
   * is triggered per page load.
   *
   * If data prop is supplied, the page view event will be tracked only once data is loaded (!= null).
   * This ensures other tracking contexts that rely on the data are ready when the event is tracked.
   * Whenever the data prop changes, additional page view events are tracked.
   *
   * If data prop is not supplied, the page view event will be tracked immediately.
   */
  data?: Record<string, unknown> | null
  children: React.ReactNode
}

export const PageTrackingContextProvider = <T extends PageName>({
  name: pageName,
  tab,
  data,
  children,
}: PageTrackingContextProviderProps<T>) => {
  const { asPathWithoutQuery, queryParamsString } = useRouter()

  const properties: PageContext = useMemo(() => {
    const clientProperties = IS_SERVER
      ? PAGE_CONTEXT_SERVER_DEFAULTS
      : {
          title: document.title,
          url: window.location.href,
        }

    return {
      name: pageName,
      tab,
      path: asPathWithoutQuery,
      queryString: queryParamsString,
      ...clientProperties,
    }
  }, [asPathWithoutQuery, pageName, queryParamsString, tab])

  return (
    <TrackingContextProvider name="Page" properties={properties}>
      <PageViewTracker data={data} name={pageName} tab={tab} />
      {children}
    </TrackingContextProvider>
  )
}

/**
 * @generated SignedSource<<585bf7aaeb699935a0d7003a213c7e42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionInspiredBy_data$data = {
  readonly inspiredBy: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string;
        readonly slug: string;
        readonly " $fragmentSpreads": FragmentRefs<"collection_url">;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "CollectionInspiredBy_data";
};
export type CollectionInspiredBy_data$key = {
  readonly " $data"?: CollectionInspiredBy_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionInspiredBy_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionInspiredBy_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 2
        }
      ],
      "concreteType": "CollectionTypeConnection",
      "kind": "LinkedField",
      "name": "inspiredBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CollectionType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "collection_url",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isCategory",
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "inspiredBy(first:2)"
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};
})();

(node as any).hash = "c58e90aceb199c6c0a7e08ad15b49240";

export default node;

import React, { useEffect, useMemo } from "react"
import { Container } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { CollectionHeroBanner } from "@/components/layout/AccountOrCollectionPage/components/CollectionHeroBannerV2"
import { CollectionPageLayoutV2 } from "@/components/layout/AccountOrCollectionPage/components/CollectionPageLayoutV2"
import { SweepContextProvider } from "@/features/sweep/SweepContextProvider.react"
import { Search } from "@/hooks/useSearch"
import { CollectionPageLayout_collection$key } from "@/lib/graphql/__generated__/CollectionPageLayout_collection.graphql"
import { Maybe } from "@/lib/helpers/type"
import { CollectionTab, CollectionTabs } from "../../CollectionTabs.react"
import { CollectionFooter } from "../CollectionFooter"
import { useIsCollectionFooterEnabled } from "../CollectionFooter/useIsCollectionFooterEnabled"
import { CollectionInspiredBy } from "../CollectionInspiredBy"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"

export type CollectionPageLayoutProps = {
  dataKey: Maybe<CollectionPageLayout_collection$key>
  tab: CollectionTab
  children: React.ReactNode
  stringTraits: Search["stringTraits"]
}

export const CollectionPageLayout = ({
  dataKey,
  tab,
  children,
  stringTraits,
}: CollectionPageLayoutProps) => {
  const collection = useFragment(
    graphql`
      fragment CollectionPageLayout_collection on CollectionType {
        slug
        ...CollectionHeroBannerV2_data
        ...CollectionTabs_collection
        ...CollectionFooter_collection
        ...SweepContextProvider_collection
        ...useIsCollectionFooterEnabled_collection
        ...CollectionInspiredBy_data
      }
    `,
    dataKey ?? null,
  )

  const isCollectionFooterEnabled = useIsCollectionFooterEnabled(collection)
  const { setIsFooterShown } = useAccountOrCollectionPageContext()
  const heroBanner = useMemo(
    () => <CollectionHeroBanner dataKey={collection} />,
    [collection],
  )

  const footer = useMemo(
    () =>
      isCollectionFooterEnabled && (
        <CollectionFooter
          collection={collection}
          isOpen={tab !== "overview"}
          showSweepForm={tab === "items"}
          stringTraits={stringTraits}
        />
      ),
    [collection, isCollectionFooterEnabled, stringTraits, tab],
  )

  useEffect(() => {
    setIsFooterShown(isCollectionFooterEnabled)
  }, [isCollectionFooterEnabled, setIsFooterShown])

  const tabs = useMemo(
    () => (
      <CollectionTabs
        currentTab={tab}
        dataKey={collection}
        isLoading={!collection}
        slug={collection?.slug ?? ""}
      />
    ),
    [collection, tab],
  )

  return (
    <SweepContextProvider collection={collection}>
      <CollectionPageLayoutV2
        banner={heroBanner}
        content={
          tab !== "overview" ? <Container>{children}</Container> : children
        }
        footer={footer}
        inspiredBy={<CollectionInspiredBy dataKey={collection} />}
        tabs={tabs}
      />
    </SweepContextProvider>
  )
}

import { useEffect } from "react"
import { useTrackingFn } from "@/lib/analytics/hooks/useTrackingFn"

type PageViewTrackerProps = {
  name: string
  tab?: string
  data?: Record<string, unknown> | null
}

export const PageViewTracker = ({ name, tab, data }: PageViewTrackerProps) => {
  const trackPageView = useTrackingFn("Page View")

  useEffect(() => {
    if (data !== null) {
      trackPageView()
    }
  }, [name, tab, trackPageView, data])

  return null
}

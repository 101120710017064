import React, { CSSProperties, useState } from "react"
import { Icon, useIsLessThanSm, Text, FlexColumn, Flex } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { FreeMode, Mousewheel, Pagination, Scrollbar } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { Modal } from "@/design-system/Modal"
import { useIsMounted } from "@/hooks/useIsMounted"
import { CampaignAnnouncementModal_data$key } from "@/lib/graphql/__generated__/CampaignAnnouncementModal_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { resizeImage } from "@/lib/helpers/urls"
import { MuxVideo } from "../common/MuxVideo"
import { CampaignAnnouncementModalCard } from "./CampaignAnnouncementModalCard.react"

type CampaignAnnouncementModalProps = {
  dataKey: CampaignAnnouncementModal_data$key | null
}

const CloseIcon = ({ padding }: { padding: CSSProperties["padding"] }) => {
  return (
    <Flex
      className="absolute cursor-pointer"
      style={{ right: padding, top: padding }}
    >
      <StyledIcon aria-label="Close" size={24} value="close" />
    </Flex>
  )
}

export const CampaignAnnouncementModal = ({
  dataKey,
}: CampaignAnnouncementModalProps) => {
  const data = useFragment(
    graphql`
      fragment CampaignAnnouncementModal_data on Query {
        campaignAnnouncementModal {
          campaignId
          title
          description
          overrideUrl
          ctaText
          ctaUrl
          playbackId
          thumbnailUrl
        }
      }
    `,
    dataKey,
  )

  const isMounted = useIsMounted()
  const connectedAddress = useConnectedAddress()
  const isMobile = useIsLessThanSm()
  const [shouldShowModal, setShouldShowModal] = useState<boolean>(true)

  if (
    !data ||
    // Seeing this being returned as undefined in production.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    !data.campaignAnnouncementModal ||
    !data.campaignAnnouncementModal.length ||
    !connectedAddress ||
    !isMounted
  ) {
    return null
  }

  const isMultiFrame = data.campaignAnnouncementModal.length > 1
  const campaignData = data.campaignAnnouncementModal[0]

  // only show the os pro announcement if the user is in targeted group
  if (campaignData.campaignId === "os-pro-announcement") {
    return null
  }

  const localStorageKey = `opensea-campaign-${campaignData.campaignId}`

  if (localStorage.getItem(localStorageKey) || !shouldShowModal) {
    return null
  }

  const slides = data.campaignAnnouncementModal
    .slice(0)
    .reverse()
    .map((entry, index) => {
      return {
        id: `${entry.campaignId}${index}`,
        campaign: (
          <CampaignAnnouncementModalCard
            description={entry.description as string}
            isFinalCard={index === data.campaignAnnouncementModal.length - 1}
            localStorageKey={localStorageKey}
            overrideUrl={entry.overrideUrl as string}
            playbackId={entry.playbackId ?? undefined}
            title={entry.title}
            onClose={() => setShouldShowModal(false)}
          />
        ),
      }
    })

  const renderedSlides = slides.map(slide => (
    <SwiperSlide key={slide.id}>{slide.campaign}</SwiperSlide>
  ))

  const modalContent = isMultiFrame ? (
    <Block>
      <StyledSwiper
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className="campaign-announcement-carousel"
        direction="horizontal"
        id={`campaign-announcement-modal-${campaignData.campaignId}`}
        key={campaignData.campaignId}
        keyboard
        loop={false}
        modules={[Pagination, Mousewheel, Scrollbar, FreeMode]}
        mousewheel={{ thresholdDelta: 25 }}
        observeParents
        observeSlideChildren
        observer
        pagination={{ clickable: true }}
        preventInteractionOnTransition
        resizeObserver
        speed={500}
        threshold={25}
      >
        {renderedSlides}
      </StyledSwiper>
    </Block>
  ) : (
    <>
      {campaignData.overrideUrl && (
        <StyledModalHeader
          $imgSrc={resizeImage(campaignData.overrideUrl, { size: 1500 }) || ""}
          height={{ _: "228px", sm: "366px" }}
        ></StyledModalHeader>
      )}
      {campaignData.playbackId && (
        <MuxVideo
          autoPlay="muted"
          className="max-h-full"
          loop
          metadata={{
            video_title: campaignData.title,
          }}
          muted
          muxPlayerClassName="max-h-full"
          playbackId={campaignData.playbackId}
          playsInline
          poster={campaignData.thumbnailUrl ?? undefined}
          style={{
            // @ts-expect-error: this var is used by mux player
            "--bottom-controls": "none",
            "--media-object-fit": "cover",
            aspectRatio: "1.63",
          }}
        />
      )}
      <Modal.Body
        className="!px-4 !pb-4 !pt-0 lg:!px-6 lg:!pb-6"
        height="undefined"
      >
        <FlexColumn className="h-full">
          <FlexColumn className="grow items-center px-2 py-6 text-center sm:px-6 lg:py-8">
            <Text.Heading size={isMobile ? "small" : "medium"}>
              {campaignData.title}
            </Text.Heading>
            <Text.Body
              className="mt-2 text-secondary md:mt-3 "
              size={isMobile ? "small" : "medium"}
            >
              {campaignData.description}
            </Text.Body>
          </FlexColumn>

          {campaignData.ctaText && (
            <Flex>
              <Button
                className="h-[48px] w-full lg:h-[60px]"
                href={campaignData.ctaUrl || ""}
                size={isMobile ? "small" : "large"}
                onClick={() => {
                  localStorage.setItem(localStorageKey, "true")
                  setShouldShowModal(false)
                }}
              >
                {campaignData.ctaText}
              </Button>
            </Flex>
          )}
        </FlexColumn>
      </Modal.Body>
    </>
  )

  return (
    <StyledModalContainer
      className="overflow-hidden"
      closable
      closeOnOverlayClick
      customCloseIcon={<CloseIcon padding={isMobile ? "16px" : "24px"} />}
      initiallyOpen
      lockFocus
      position="centered"
      size={isMobile ? "xsmall" : "medium"}
      trigger={() => {
        return null
      }}
      onClose={() => {
        localStorage.setItem(localStorageKey, "true")
      }}
    >
      {modalContent}
    </StyledModalContainer>
  )
}

const StyledModalContainer = styled(Modal)`
  & i {
    top: 24px;
    right: 24px;
  }
`

const StyledSwiper = styled(Swiper)`
  background-color: rgba(0, 0, 0, 0);
  border-radius: 16px;
`

const StyledModalHeader = styled(Modal.Header)<{ $imgSrc: string }>`
  background-color: black;
  background-image: url("${props => props.$imgSrc}");
  background-size: cover;
  border-radius: 16px 16px 0 0;
  width: 100%;
  padding: 0;
`

const StyledIcon = styled(Icon)`
  color: white;
  text-shadow: black 0 0 15px;
  z-index: 100;
`

import React from "react"
import { Media, Text } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import styled from "styled-components"
import { Link } from "@/components/common/Link"
import { Flex } from "@/design-system/Flex"
import { Tooltip } from "@/design-system/Tooltip"
import { interactiveStylesPrimary } from "@/design-system/utils"
import { useTranslate } from "@/hooks/useTranslate"
import { CollectionInspiredBy_data$key } from "@/lib/graphql/__generated__/CollectionInspiredBy_data.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { getCollectionUrl } from "@/lib/helpers/collection"

type Props = {
  dataKey: CollectionInspiredBy_data$key | null
  isTooltipEnabled?: boolean
  shouldUseDefaultLinkStyle?: boolean
  alwaysTruncate?: boolean
  maintainSize?: boolean
  truncateChars?: number
}

const truncateCollectionName = (str: string, chars = 10) => {
  return `${str.slice(0, chars)}${str.length > chars ? "..." : ""}`
}

const DataText = ({
  href,
  label,
  shouldUseDefaultLinkStyle = false,
  alwaysTruncate = false,
  truncateChars = 10,
  maintainSize = false,
}: {
  href: string
  label: string
  shouldUseDefaultLinkStyle?: boolean
  alwaysTruncate?: boolean
  truncateChars?: number
  maintainSize?: boolean
}) => {
  return (
    <Link className="whitespace-nowrap" href={href}>
      <Media lessThan="md">
        <LinkText
          color={shouldUseDefaultLinkStyle ? "inherit" : undefined}
          size={maintainSize ? "medium" : "small"}
          weight={shouldUseDefaultLinkStyle ? "regular" : "semibold"}
        >
          {truncateCollectionName(label, truncateChars)}
        </LinkText>
      </Media>
      <Media greaterThanOrEqual="md">
        <LinkText
          color={shouldUseDefaultLinkStyle ? "inherit" : undefined}
          size="medium"
          weight={shouldUseDefaultLinkStyle ? "regular" : "semibold"}
        >
          {alwaysTruncate
            ? truncateCollectionName(label, truncateChars)
            : label}
        </LinkText>
      </Media>
    </Link>
  )
}

export const CollectionInspiredBy = ({
  dataKey,
  isTooltipEnabled = true,
  shouldUseDefaultLinkStyle = false,
  alwaysTruncate,
  maintainSize = false,
  truncateChars = 10,
}: Props) => {
  const t = useTranslate("collection")
  const data = useFragment(
    graphql`
      fragment CollectionInspiredBy_data on CollectionType {
        inspiredBy(first: 2) {
          edges {
            node {
              slug
              name
              ...collection_url
            }
          }
        }
      }
    `,
    dataKey,
  )

  if (!data || !data.inspiredBy.edges.length) {
    return null
  }

  const hasTwoCollections = data.inspiredBy.edges.length === 2

  const nodes = getNodes(data.inspiredBy)

  const content = (
    <>
      {hasTwoCollections
        ? t(
            "inspiredBy.labelMultiple",
            "Inspired{{ws}}by{{ws}}{{collectionOne}}{{ws}}and{{ws}}{{collectionTwo}}",
            {
              ws: <span>&nbsp;</span>,
              collectionOne: (
                <DataText
                  alwaysTruncate={alwaysTruncate}
                  href={getCollectionUrl(nodes[0])}
                  key={nodes[0].slug}
                  label={nodes[0].name}
                  maintainSize={maintainSize}
                  shouldUseDefaultLinkStyle={shouldUseDefaultLinkStyle}
                  truncateChars={truncateChars}
                />
              ),
              collectionTwo: (
                <DataText
                  alwaysTruncate={alwaysTruncate}
                  href={getCollectionUrl(nodes[1])}
                  key={nodes[1].slug}
                  label={nodes[1].name}
                  maintainSize={maintainSize}
                  shouldUseDefaultLinkStyle={shouldUseDefaultLinkStyle}
                  truncateChars={truncateChars}
                />
              ),
            },
          )
        : t("inspiredBy.label", "Inspired by {{collection}}", {
            collection: (
              <DataText
                href={getCollectionUrl(nodes[0])}
                key={nodes[0].slug}
                label={nodes[0].name}
                maintainSize={maintainSize}
                shouldUseDefaultLinkStyle={shouldUseDefaultLinkStyle}
                truncateChars={truncateChars * 2}
              />
            ),
          })}
    </>
  )

  return (
    <Flex className="whitespace-break-spaces" marginY="4px">
      <Tooltip
        content={t(
          "inspiredBy.tooltip",
          "Similar to other collections. {{learnMore}}",
          {
            learnMore: (
              <Link href="https://support.opensea.io/articles/8867066">
                {t("inspiredBy.tooltipLearnMore", "Learn More")}
              </Link>
            ),
          },
        )}
        disabled={!isTooltipEnabled}
        interactive
      >
        <Flex>
          <Media lessThan="md">
            <Text.Body size={maintainSize ? "medium" : "small"}>
              <Flex flexWrap="nowrap">{content}</Flex>
            </Text.Body>
          </Media>
          <Media greaterThanOrEqual="md">
            <Text.Body>
              <Flex>{content}</Flex>
            </Text.Body>
          </Media>
        </Flex>
      </Tooltip>
    </Flex>
  )
}

const LinkText = styled(Text.Body)`
  ${interactiveStylesPrimary}
`
